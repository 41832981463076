<template>
  <b-card>
    <div class="d-flex justify-content-between align-items-center">
      <h6>
        {{ `${$t('fields.result')} (${history.length})` }}
      </h6>
      <label class="d-inline-flex align-items-center">
        <b-input-group>
          <b-form-input
            v-model="filterPlayerCodeName"
            type="search"
            class="mx-1"
            :placeholder="`${$t('fields.search')}... (${$t('user.prefix')})`"
          ></b-form-input>
        </b-input-group>
        <b-input-group>
          <b-form-input
            v-model="filterPlayerFirstname"
            type="search"
            class="mx-1"
            :placeholder="`${$t('fields.search')}... (${$t('user.firstname')})`"
          ></b-form-input>
        </b-input-group>
        <b-input-group>
          <b-form-input
            v-model="filterPlayerLastname"
            type="search"
            class="mx-1"
            :placeholder="`${$t('fields.search')}... (${$t('user.lastname')})`"
          ></b-form-input>
        </b-input-group>
        <b-input-group>
          <b-form-input
            v-model="filterPlayerUsername"
            type="search"
            :placeholder="`${$t('fields.search')}... (${$t('user.username')})`"
          ></b-form-input>
        </b-input-group>
        <b-dropdown variant="light" class="ml-md-2 w-100">
          <template slot="button-content">
            <i class="uil uil-filter"></i>
            <span>
              ประเภทรายการ
            </span>
            <i class="uil uil-angle-down"></i>
          </template>

          <b-dropdown-item-button
            :active="filterType === `deposit`"
            @click="filterType = 'deposit'"
            >{{ $t('credits.deposit') }}
          </b-dropdown-item-button>
          <b-dropdown-item-button
            :active="filterType === `direct_deposit`"
            @click="filterType = 'direct_deposit'"
            >{{ $t('credits.direct_deposit') }}
          </b-dropdown-item-button>
          <b-dropdown-item-button
            :active="filterType === `withdraw`"
            @click="filterType = 'withdraw'"
            >{{ $t('credits.withdraw') }}
          </b-dropdown-item-button>
          <b-dropdown-item-button
            :active="filterType === `direct_withdraw`"
            @click="filterType = 'direct_withdraw'"
            >{{ $t('credits.direct_withdraw') }}
          </b-dropdown-item-button>
          <b-dropdown-divider />
          <b-dropdown-item-button @click="filterType = ''">
            {{ $t('buttons.clear') }}
          </b-dropdown-item-button>
        </b-dropdown>
      </label>
    </div>
    <!-- Table -->
    <div></div>
    <b-table
      :items="history"
      :fields="pendingFields"
      :busy="credit.isFetchingPlayersCreditHistory"
      responsive
      show-empty
    >
      <template #cell(#)="data">
        {{ data.index + 1 }}
      </template>
      <template #cell(created_at)="data">
        {{ data.value | datetime }}
      </template>
      <template #cell(perform_by)="data">
        {{ data.value || '-' }}
      </template>
      <template #cell(username)="data">
        <b-link
          :to="
            data.item.user_id
              ? `/players/detail/${data.item.user_id}`
              : `/players/list`
          "
        >
          {{ `${data.value}` }}
        </b-link>
      </template>
      <template #cell(type)="data">
        <b-badge
          v-if="data.value === 'deposit' || data.value === 'direct_deposit'"
          class="badge-soft-success"
        >
          {{
            data.value === 'direct_deposit'
              ? $t('credits.direct_deposit')
              : $t('credits.deposit')
          }}
        </b-badge>
        <b-badge
          v-if="data.value === 'withdraw' || data.value === 'direct_withdraw'"
          class="badge-soft-danger"
        >
          {{
            data.value === 'direct_withdraw'
              ? $t('credits.direct_withdraw')
              : $t('credits.withdraw')
          }}
        </b-badge>
      </template>
      <template #cell(transaction_amount)="data">
        <span
          v-if="
            data.item.type === 'deposit' || data.item.type === 'direct_deposit'
          "
          class="text-success"
        >
          {{ data.value }}
        </span>
        <span v-else class="text-danger"> - {{ data.value }} </span>
      </template>
      <template #cell(before_amount)="data">
        {{ data.value | currency }}
      </template>
      <template #cell(after_amount)="data">
        {{ data.value | currency }}
      </template>
      <template #cell(status)="data">
        <b-badge v-if="data.value === 'success'" variant="success">
          {{ $t('utils.status.success_credit') }}
        </b-badge>
        <b-badge v-else-if="data.value === 'waiting'" variant="info">
          {{ $t('utils.status.waiting') }}
        </b-badge>
        <b-badge v-else-if="data.value === 'processing'" variant="warning">
          {{ $t('utils.status.processing') }}
        </b-badge>
        <b-badge v-else variant="danger">
          {{ $t('utils.status.unsuccessful') }}
        </b-badge>
      </template>
      <template #cell(detail)="data">
        {{ data.value || '-' }}
      </template>
      <template #cell(channel)="data">
        <b-badge> {{ data.value || '-' }} </b-badge>
      </template>
      <template #table-busy>
        <div class="text-center my-2">
          <b-spinner class="align-middle"></b-spinner>
        </div>
      </template>
      <template #empty="">
        <p class="text-center text-muted">{{ $t('messages.nothing_here') }}</p>
      </template>
    </b-table>
    <!-- pagination -->
    <PaginationInput :per-page="limit" :total="totalRow" @update="(val) => currentPage = val"/>
  </b-card>
</template>

<script>
import { mapActions, mapGetters, mapState } from 'vuex'

export default {
  page: {
    title: 'Credit Transactions',
  },
  data() {
    return {
      currentPage: 1,
      limit: 50,
      offset: 0,
      filter: '',
      filterPlayerCodeName: '',
      filterPlayerFirstname: '',
      filterPlayerLastname: '',
      filterPlayerUsername: '',
      filterType: '',
      onSearchTimeout: null,
      pendingFields: [
        '#',
        {
          key: 'created_at',
          label: `${this.$t('fields.issued_date')}`,
          thStyle: {
            minWidth: '130px',
          },
        },
        {
          key: 'perform_by',
          label: `${this.$t('credits.operated_by')}`,
          thStyle: {
            minWidth: '120px',
          },
        },
        {
          key: 'username',
          label: this.$t('credits.receiver'),
          thStyle: {
            minWidth: '100px',
          },
        },
        {
          key: 'type',
          label: this.$t('credits.type'),
          class: 'text-center',
          thStyle: {
            minWidth: '120px',
          },
        },
        {
          key: 'before_amount',
          label: this.$t('credits.before_amount'),
          class: 'text-right',
          thStyle: {
            minWidth: '150px',
          },
        },
        {
          key: 'transaction_amount',
          label: this.$t('credits.credit_amount'),
          class: 'text-right',
          thStyle: {
            minWidth: '150px',
          },
        },
        {
          key: 'after_amount',
          label: this.$t('credits.remain_credits'),
          class: 'text-right',
          thStyle: {
            minWidth: '150px',
          },
        },
        {
          key: 'channel',
          label: this.$t('fields.channel'),
          class: 'text-center',
          thStyle: {
            minWidth: '100px',
          },
        },
        {
          key: 'status',
          label: this.$t('fields.status'),
          class: 'text-center',
          thStyle: {
            minWidth: '100px',
          },
        },
        {
          key: 'detail',
          label: this.$t('fields.remark'),
          thStyle: {
            minWidth: '150px',
          },
        },
      ],
    }
  },
  computed: {
    ...mapState(['credit']),
    ...mapGetters(['playersCreditHistory']),
    history() {
      return this.playersCreditHistory.data || []
    },
    totalRow() {
      return this.playersCreditHistory.total || 0
    },
  },
  watch: {
    currentPage(val) {
      const nextPage = val - 1
      const nextVal = nextPage * this.limit
      this.offset = nextVal
      this.fetchData()
    },
    filter(val) {
      if (val) {
        this.fetchDataByFilter(val)
      }
    },
    filterPlayerUsername() {
      this.delaySearch()
    },
    filterPlayerFirstname() {
      this.delaySearch()
    },
    filterPlayerLastname() {
      this.delaySearch()
    },
    filterPlayerCodeName() {
      this.delaySearch()
    },
  },
  created() {
    this.fetchData()
  },
  methods: {
    ...mapActions(['fetchPlayersCreditHistory']),
    fetchData() {
      this.fetchPlayersCreditHistory({
        limit: this.limit,
        offset: this.offset,
        username: this.filterPlayerUsername,
        firstname: this.filterPlayerFirstname,
        lastname: this.filterPlayerLastname,
        code_name: this.filterPlayerCodeName,
        type: this.filterType,
      })
    },
    fetchDataByFilter() {},
    delaySearch() {
      clearTimeout(this.onSearchTimeout)
      this.onSearchTimeout = setTimeout(this.fetchData, 800)
    },
  },
}
</script>
